export default {
  text: '#555555',
  text2: '#555555',
  primary: '#3c97cb',
  secondary: '#3c97cb',
  background: '#ffffff',
  backgroundPrimary: '#ffffff',
  backgroundSecondary: '#ffffff',
  backgroundTertiary: '#ffffff',
  light: '#FFF',
  dark: '#555555'
}
